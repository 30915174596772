import apiClient from "./index.js";

const relative_path = "general";

export default {
  /**Funcion que trae todos los departamentos de colombia */
  getDepartamentosColombia() {
    return apiClient.get(relative_path + "/departamentos-colombia");
  },

  /**Funcion que trae los edificios-barrios-comunas pertenecientes a una ciudad en especifico */
  getBarriosEdificiosComunas(id_ciudad) {
    return apiClient.get(
      relative_path + "/barrios-edificios-comunas/" + id_ciudad
    );
  },

  /**Funcion que retorna los tipos de vias existentes en db */
  getTipoVias() {
    return apiClient.get(relative_path + "/tipo-via");
  },

  /**Funcion que retorna los tipos bloques interiores existentes en db */
  getTipoBloqueInterior() {
    return apiClient.get(relative_path + "/tipo-bloque");
  },

  /**Funcion que retorna los tipos de inmuebles existentes en db */
  getTipoInmueble() {
    return apiClient.get(relative_path + "/tipo-inmueble");
  },
};
