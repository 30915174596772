<template>
  <v-card>
    <v-toolbar dark height="40" color="grey darken-3">
      <v-spacer></v-spacer>
      <v-toolbar-title>Dirección</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div v-if="!local_loader">
      <v-card-text>
        <v-row class="mb-2 mb-sm-n7 mb-lg-n8">
          <!-- DIRECCION MANUAL -->
          <v-col cols="12" sm="7" md="8" lg="8">
            <label>Direccion Manual</label>
            <v-text-field
              dense
              outlined
              @keyup="validarTipoDireccion"
              v-model="direccion_manual"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" md="4" lg="4">
            <v-col
              cols="12"
              class="d-flex justify-center mt-n2 mt-sm-6 mt-lg-6"
            >
              <v-btn
                color="red"
                dark
                @click="$emit('cerrar')"
                class="mr-2 mr-sm-2 mr-lg-3"
              >
                Cerrar</v-btn
              >

              <v-btn
                :disabled="!validarBtnGuardar"
                color="primary"
                @click="guardar()"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-col>
        </v-row>

        <!-- DATOS ESPECIFICOS DE DIRECCIONES -->
        <v-row>
          <!--Nota: Se separo los datos especificos de la tabla por columnas ya que en vista movil se necesita que el orden de las columnas sea diferente y lo primero que se muestre sea el select de las ciudades -->
          <v-col
            cols="12"
            sm="6"
            order="last"
            order-sm="first"
            order-lg="first"
          >
            <!-- BLOQUE INTERIOR -->
            <v-row class="mb-n10">
              <!-- TIPO DE BLOQUE INTERIOR -->
              <v-col cols="8">
                <label
                  :class="
                    direccion_seleccionable_inactiva ? 'label_desactivado' : ''
                  "
                  >Bloque interior</label
                >
                <v-select
                  v-if="!cargando_tipos_bloque_interior"
                  :items="items_select_bloque_interior"
                  item-value="clave"
                  item-text="valor"
                  :disabled="
                    direccion_seleccionable_inactiva && !direccion_neutra
                  "
                  outlined
                  dense
                  @keyup="validarTipoDireccion"
                  v-model="bloque_interior"
                  v-on:change="reiniciarValor($event, 'bloque')"
                ></v-select>
                <div v-else class="skeleton-loader"></div>
              </v-col>

              <!-- NUMERO DE BLOQUE INTERIOR -->
              <v-col cols="5" sm="4" lg="4" class="mr-0 mr-sm-6 mr-lg-5 ml-n6">
                <label
                  style="text-align: center; display: block"
                  :class="
                    direccion_seleccionable_inactiva ? 'label_desactivado' : ''
                  "
                  >N°</label
                >
                <v-text-field
                  v-model="n_bloque_interior"
                  dense
                  @keyup="validarTipoDireccion"
                  outlined
                  :disabled="
                    bloque_interior == 1 ||
                    (direccion_seleccionable_inactiva && !direccion_neutra)
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- FIN BLOQUE INTERIOR -->

            <!-- TIPO DE INMUEBLE -->
            <v-row class="mt-n3">
              <!-- TIPO DE INMUEBLE -->
              <v-col cols="8" class="mr-n6">
                <label
                  :class="
                    direccion_seleccionable_inactiva ? 'label_desactivado' : ''
                  "
                  >Tipo de Inmueble</label
                >
                <v-select
                  v-if="!cargando_tipos_inmueble"
                  :items="items_select_tipo_inmueble"
                  :disabled="
                    direccion_seleccionable_inactiva && !direccion_neutra
                  "
                  item-text="valor"
                  item-value="clave"
                  outlined
                  dense
                  v-model="tipo_inmueble"
                  v-on:change="reiniciarValor($event, 'inmueble')"
                ></v-select>
                <div v-else class="skeleton-loader"></div>
              </v-col>

              <!-- NUMERO DE TIPO INMUEBLE -->
              <v-col cols="5" sm="4" lg="4">
                <label
                  style="text-align: center; display: block"
                  :class="
                    direccion_seleccionable_inactiva ? 'label_desactivado' : ''
                  "
                  >N°</label
                >
                <v-text-field
                  v-model="n_tipo_inmueble"
                  dense
                  outlined
                  @keyup="validarTipoDireccion"
                  :disabled="
                    tipo_inmueble == 1 || direccion_seleccionable_inactiva
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- FIN DE TIPO DE INMUEBLE -->

            <!-- TIPO DE VIA -->
            <v-row class="mt-n3">
              <!-- TIPO DE VIA -->
              <v-col cols="12" class="mt-n4">
                <label
                  :class="
                    direccion_seleccionable_inactiva ? 'label_desactivado' : ''
                  "
                  >Tipo de Via</label
                >
                <v-select
                  v-if="!cargando_tipos_via"
                  :items="items_select_tipo_via"
                  item-value="clave"
                  item-text="valor"
                  outlined
                  dense
                  :disabled="direccion_seleccionable_inactiva"
                  v-model="tipo_via"
                  v-on:change="reiniciarValor($event, 'direccion')"
                  style="width: 94%"
                ></v-select>
                <div
                  v-else
                  class="skeleton-loader"
                  style="margin-bottom: 28px; width: 94%"
                ></div>
              </v-col>
            </v-row>
            <!-- FIN TIPO DE VIA -->

            <!-- DIRECCIÓN -->
            <v-row>
              <v-col cols="12" class="mt-n4">
                <v-row>
                  <!-- CRA/CLL -->
                  <v-col sm="4" class="">
                    <label
                      :class="
                        direccion_seleccionable_inactiva
                          ? 'label_desactivado'
                          : ''
                      "
                      >Dirección</label
                    >
                    <v-text-field
                      v-model="direccion_cra"
                      dense
                      outlined
                      @keyup="validarTipoDireccion"
                      :disabled="
                        tipo_via == 1 || direccion_seleccionable_inactiva
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1" class="ml-n9 ml-sm-n6 ml-lg-n6 mt-5">
                    <div
                      class="box-direccion"
                      style="border-radius: 2px; width: 35px; margin-top: 1px"
                    >
                      #
                    </div>
                  </v-col>

                  <!-- # DIRECCIÓN -->
                  <v-col sm="3" style="margin-top: 22px">
                    <v-text-field
                      v-model="direccion_numero"
                      dense
                      @keyup="validarTipoDireccion"
                      outlined
                      :disabled="
                        tipo_via == 1 || direccion_seleccionable_inactiva
                      "
                      placeholder="#"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1" class="ml-n8 ml-sm-n6 ml-lg-n5 mt-5">
                    <div
                      class="box-direccion"
                      style="border-radius: 2px; width: 35px"
                    >
                      N°
                    </div>
                  </v-col>
                  <!-- N° DIRECCIÓN -->
                  <v-col sm="3" class="mt-5">
                    <v-text-field
                      v-model="direccion_n"
                      dense
                      outlined
                      @keyup="validarTipoDireccion"
                      placeholder="N°"
                      :disabled="
                        tipo_via == 1 || direccion_seleccionable_inactiva
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- FIN DIRECCION -->

            <!-- SWTICH Y BOTONES (ASIGNAR CORDENADAS - SIN VALIDAR) -->
            <v-row>
              <!-- SWITCH PARQUEADERO -->
              <v-col
                cols="12"
                sm="5"
                class="d-flex justify-center justify-sm-start justify-lg-start"
              >
                <v-switch
                  class="ml-2"
                  v-model="switch_parqueadero"
                  dense
                  label="Parqueadero"
                ></v-switch>

                <!-- Botón asigar Cordenadas -->
              </v-col>

              <!-- CORDENADAS -->
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center justify-sm-start justify-lg ml-0 ml-sm-n5 ml-lg-n5 mt-1 mt-sm-3 mt-lg-3"
              >
                <v-dialog persistent fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-4 white--text"
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="grey darken-2"
                    >
                      Asignar Coordenadas
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar dark color="dark" height="40">
                        <v-spacer></v-spacer>
                        <p>Asignar Coordenadas</p>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <!-- <geo-location-map ref="geo"></geo-location-map> -->
                      </v-card-text>
                      <v-card-actions class="mt-n8">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          dark
                          small
                          @click="dialog.value = false"
                        >
                          Cerrar
                        </v-btn>
                        <v-btn
                          color="green"
                          dark
                          small
                          @click="saveLocation(), (dialog.value = false)"
                        >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
          <!-- FIN SWTICH Y BOTONES (ASIGNAR CORDENADAS - SIN VALIDAR) -->

          <!-- SEGUNDA COLUMNA DE CIUDADES Y BARRIOS -->
          <v-col cols="12" sm="6" order="first" order-sm="last" order-lg="last">
            <v-row>
              <!-- CIUDAD -->
              <v-col class="mt-3" cols="12">
                <label>Ciudad</label>
                <v-autocomplete
                  v-if="!cargando_ciudades"
                  @change="buscarBarrios"
                  :items="ciudades"
                  item-text="valor"
                  item-value="clave"
                  outlined
                  dense
                  v-model="ciudad"
                ></v-autocomplete>
                <!-- INPUT DE CARGA -->
                <div
                  v-else
                  class="skeleton-loader"
                  style="margin-bottom: 28px"
                ></div>
              </v-col>

              <!-- BARRIO -->
              <v-col cols="12">
                <a @click="modal_agregar_barrio = true"
                  >Barrio - Unidad Residencial - Comuna
                </a>
                <v-autocomplete
                  v-if="!cargando_barrios"
                  @change="buscarEdificios"
                  :items="items_select_barrio"
                  item-text="valor"
                  item-value="clave"
                  outlined
                  dense
                  v-model="barrio"
                ></v-autocomplete>
                <!-- INPUT DE CARGA -->
                <div
                  v-else
                  class="skeleton-loader"
                  style="margin-bottom: 27px"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <!-- COMUNA -->
              <v-col cols="12" class="mt-n4">
                <label class="label_desactivado">Comuna</label>
                <v-text-field
                  v-model="comuna"
                  dense
                  hide-details
                  outlined
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <!-- OBSERVACIONES COMUNA -->
              <v-col cols="12" class="mt-4">
                <v-textarea
                  label="Observaciones Comuna"
                  disabled
                  outlined
                  height="70"
                  v-model="observaciones_barrio"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <!-- ACLARACIONES -->
          <v-col cols="12" sm="6" class="mb-n4 mb-sm-0 mb-lg-0">
            <v-text-field
              label="Aclaraciones"
              outlined
              :disabled="direccion_seleccionable_inactiva"
              dense
              v-model="aclaraciones"
            ></v-text-field>
          </v-col>

          <!-- OBSERVACIONES  -->
          <v-col cols="12" sm="6">
            <v-text-field
              label="Observaciones Direccion"
              outlined
              dense
              v-model="observaciones"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <!-- ICONO DE CARGA DE LA VISTA -->
    <div v-else style="height: 400px; display: flex; align-items: center">
      <v-spacer></v-spacer>
      <v-progress-circular
        :size="150"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-spacer></v-spacer>
    </div>

    <!-- MODAL AGREGAR BARRIO -->
    <!-- <v-dialog v-model="modal_agregar_barrio" width="400px">
      <ModalAgregarBarrio
        v-if="modal_agregar_barrio"
        :ciudad_prop="ciudad"
        @barrio-creado="agregarBarrio"
        @cerrar="modal_agregar_barrio = false"
        @edificio-creado="agregarEdificio"
      ></ModalAgregarBarrio>
    </v-dialog> -->
  </v-card>
</template>

<script>
// import GeoLocationMap from '../GeoLocationMap.vue'
import generalService from "../services/generalService.js";

export default {
  name: "Direccion",
  props: ["json_direccion"],
  data: () => ({
    //---------- VARIABLES GENERALES ---------------
    /**Variable que activa el icono de carga del modal al momento de traer los datos en el created */
    local_loader: false,

    /**Variable que desactiva el input de direccion manual del modal */
    direccion_manual_inactiva: false,

    /**Variable que deja activo tanto los inputs de la direccion mas especifica como de la direccion manual activos en caso de que no haya nada escrito en ellos */
    direccion_neutra: false,

    /**Variable que desactiva todos los inputs de la direcicon mas especifica */
    direccion_seleccionable_inactiva: false,

    /**Variable que determina si se estan buscando los resultados de los barrios y activa el icono de carga en el input de barrios */
    cargando_barrios: false,

    /**Variable que determina si se estan buscando los resultados de las ciudades y activa el icono de carga en el input de ciudades */
    cargando_ciudades: false,

    /**Variable que determina si se estan buscando los resultados de los tipos de bloque interior y activa el icono de carga en el input de tipos de bloque interior */
    cargando_tipos_bloque_interior: false,

    /**Variable que determina si se estan buscando los resultados de los tipos de inmueble y activa el icono de carga en el input de tipos de inmueble */
    cargando_tipos_inmueble: false,

    /**Variable que determina si se estan buscando los resultados de los tipos de via y activa el icono de carga en el input de tipos de via */
    cargando_tipos_via: false,

    //Direccion Manual

    /**Variable que remplaza la direccion mas especifica para facilidad del usuario */
    direccion_manual: "",

    //Ciudad
    ciudad: 13358,
    ciudades: [],
    con_covertura: false,
    id_munydpt:false,

    //Barrio
    barrio: null,
    items_select_barrio: [],

    //Edificio
    edificio: null,

    //Tipo de Bloque interior
    bloque_interior: 1,
    items_select_bloque_interior: [],
    n_bloque_interior: "",

    //Tipo de Inmueble
    tipo_inmueble: 1,
    items_select_tipo_inmueble: [],
    n_tipo_inmueble: "",

    //Tipo de via
    tipo_via: 1,
    items_select_tipo_via: [],

    //Direccion campos rellenar
    direccion_cra: "",
    direccion_numero: "",
    direccion_n: "",

    //Parqueadero
    switch_parqueadero: null,

    //Cordenadas
    latitud: null,
    longitud: null,

    //Observaciones
    observaciones: "",

    //Aclaraciones
    aclaraciones: "",

    //Comuna
    comuna: "",
    observaciones_barrio: "",

    //---------- MODALES -------------

    //Modal Agregar Barrio
    modal_agregar_barrio: false,
  }),
  components: {
    // GeoLocationMap
    // ModalAgregarBarrio,
  },

  methods: {
    /**
     * Funcion que agrega un nuevo barrio a la vista
     * @param {object} nuevo_barrio nuevo barrio a ser integrado a la vista
     * @returns {void}
     */
    async agregarBarrio(nuevo_barrio) {
      if (this.ciudad != nuevo_barrio.id_ciudad) {
        //Se valida si la ciudad del barrio creado es diferente de la ciudad que se encuentra actualmente en la vista
        await this.buscarBarrios(nuevo_barrio.id_ciudad); //En el caso de que lo sea busca todos los barrios de esta ciudad en DB
      } else {
        /**Variable que va permitir asignarle un identificador a un nuevo barrio cuando este en la vista va aparecer sin ningun edificio */
        let clave_provisional = 0;

        this.items_select_barrio.forEach((element) => {
          if (element.clave < 0) {
            //Se obtiene el ultimo registro de los barrio que no tienen edificio en la vista
            clave_provisional = element.clave - 1;
          }
        });

        this.items_select_barrio.push({
          valor: nuevo_barrio.barrio + " - " + nuevo_barrio.comuna,
          clave: clave_provisional,
          id_barrio: nuevo_barrio.id,
          barrio: nuevo_barrio.barrio,
          id_edificio: null,
          edificio: null,
          comuna: nuevo_barrio.comuna,
          observaciones: nuevo_barrio.observaciones_comuna,
        }); //Se agrega al array de los barrios
      }

      this.ciudad = nuevo_barrio.id_ciudad; //Se asigna el id de la ciudad con la que se creo el barrio

      this.items_select_barrio.forEach((element) => {
        if (element.id_barrio == nuevo_barrio.id) {
          //Se obtiene la clave del ultimo registro ingresado al array que concuerda con el id de barrio creado
          this.barrio = element.clave;
        }
      });

      this.buscarEdificios(this.barrio); //Se buscan las comunas de este edificio
    },

    /**
     * Funcion que agrega un nuevo edificio a la vista
     * @param {object} nuevo_edificio
     * @returns {void}
     */
    agregarEdificio(nuevo_edificio) {
      if (nuevo_edificio.id_ciudad == null) {
        //Se valida si la ciudad del nuevo edificio viene vacia
        nuevo_edificio.id_ciudad = 13358; //En el caso de que venga vacia se pone la que biene por default
      }

      this.buscarBarrios(nuevo_edificio.id_ciudad); //Se buscan los sbarrios que le pertenecen a la ciudad del edificio creado

      this.items_select_barrio.forEach((element) => {
        if (element.id_edificio == nuevo_edificio.id_edificio) {
          //Se obtiene la clave del ultimo registro ingresado al array que concuerda con el id del edificio creado
          this.barrio = element.clave;
        }
      });
    },

    /**
     * Funcion que guarda la direccion
     */
    guardar() {
      /**Variable que va contener la direccion concatenada de todos los parametos seleccionados en la direccion en un solo texto */
      let direccion_completa = "";

      /**Variable que va contener la direccion concatenada de todos los parametos seleccionados en la direccion en un solo texto con el formato requerido por la DIAN */
      let direccion_dian = "";

      /**Variable que va contener el id del barrio seleccionado */
      let barrio = null;

      this.items_select_barrio.forEach((element) => {
        if (element.clave == this.barrio) {
          //Se obtiene el id del barrio que se selecciono
          barrio = element.id_barrio;
        }
      });

      if (this.direccion_manual_inactiva) {
        //Se valida si la direccion manual esta desactivada

        //VALIDACION DE TIPO DE BLOQUE INTERIOR
        if (
          this.n_bloque_interior.length > 0 &&
          this.bloque_interior != 1 &&
          this.bloque_interior != null
        ) {
          //Se valida que el campo de numero de bloque no este vacio y aparte que el select de tipo de bloque interior no sea nulo o 1(No aplica)
          this.items_select_bloque_interior.forEach((element) => {
            //Se itera sobre los valores del array de tipos de bloque interior
            if (element.clave == this.bloque_interior) {
              //Se le asigna a la direccion completa el tipo de bloque interior y el numero que fue escrito en este
              direccion_completa +=
                element.valor + " " + this.n_bloque_interior + " ";

              //Se le asigna a la direcion de la dian la nomenclatura del tipo de bloque interior y el numero que fue escrito en el numero de bloque interior
              direccion_dian +=
                element.nomenclatura_dian + " " + this.n_bloque_interior + " ";
            }
          });
        }

        //VALIDACION DE TIPO DE INMUEBLE
        if (
          this.n_tipo_inmueble.length > 0 &&
          this.tipo_inmueble != 1 &&
          this.tipo_inmueble != null
        ) {
          //Se valida que el campo de numero de inmueble no este vacio y aparte que el select de tipo de inmueble no sea nulo o 1(No aplica)
          this.items_select_tipo_inmueble.forEach((element) => {
            if (element.clave == this.tipo_inmueble) {
              //Se valida que concuerde con el tipo de inmueble seleccionado
              if (this.edificio != null) {
                //Se valida si se ha seleccionado un edicio o no
                this.items_select_barrio.forEach((element2) => {
                  //Se itera sobre los items del barrio ya que contiene los datos de los edicios,barrios y comunas
                  if (element2.id_edificio == this.edificio) {
                    if (this.tipo_inmueble != 3 && this.tipo_inmueble != 4) {
                      //Se valida que el tipo de inmueble no sea 3(Edificio) o 4 (Hotel) Para no agregar el prefijo 'Unidad Residencial' a la direccion completa

                      //Se le asigna a la direccion completa el tipo de inmueble y el numero que fue escrito en este,el prefijo de unidad residencial y el nombre del edificio seleccionado
                      direccion_completa +=
                        element.valor +
                        " " +
                        this.n_tipo_inmueble +
                        " Unidad Residencial " +
                        element2.edificio +
                        " ";

                      //Se le asigna a la direccion dian la nomenclatura del inmueble y el numero que fue escrito en este,el prefijo de la unidad residencial 'UR' y el nombre del edificio seleccionado
                      direccion_dian +=
                        element.nomenclatura_dian +
                        " " +
                        this.n_tipo_inmueble +
                        " UR " +
                        element2.edificio.toUpperCase() +
                        " ";
                    } else {
                      //Se le asigna a la direccion completa el tipo de inmueble y el numero que fue escrito en este y el nombre del edificio seleccionado
                      direccion_completa +=
                        element.valor +
                        " " +
                        this.n_tipo_inmueble +
                        " " +
                        element2.edificio +
                        " ";

                      //Se le asigna a la direccion dian el tipo de inmueble y el numero que fue escrito en este y el nombre del edificio seleccionado
                      direccion_dian +=
                        element.nomenclatura_dian +
                        " " +
                        this.n_tipo_inmueble +
                        " " +
                        element2.edificio.toUpperCase() +
                        " ";
                    }
                  }
                });
              } else {
                //En el caso de que no se haya seleccionado un edificio

                //Se le asigna a la direccion completa el tipo de inmueble y el numero del tipo de inmueble
                direccion_completa +=
                  element.valor + " " + this.n_tipo_inmueble + " ";

                //Se le asigna a la direccion dian el la nomenclatura en la dian del tipo de inmueble y el numero del tipo de inmueble
                direccion_dian +=
                  element.nomenclatura_dian + " " + this.n_tipo_inmueble + " ";
              }
            }
          });
        } else if (this.edificio != null) {
          //En el caso de que el tipo de inmueble no haya sido seleccionado se valida si hay algun edificio seleccionado
          this.items_select_barrio.forEach((element) => {
            if (element.clave == this.barrio) {
              //Se le agrega a la direccion completa el prefijo de unidad residencial y el nombre del edificio seleccionado
              direccion_completa +=
                " Unidad Residencial " + element.edificio + " ";

              //Se le agrega a la direccion dian la nomencaltura de unidad residencial 'UR' y el nombre del edificio seleccionado
              direccion_dian += " UR " + element.edificio.toUpperCase() + " ";
            }
          });
        }

        //VALIDACION DE DIRECCION NUMERO,NUMERAL Y CARRERA

        //Se valida que el numeral de la direccion,el numero y la carrera no esten vacios,asi mismo se valida que el tipo de via no sea 1(No aplica) o 12(Otros)
        if (
          this.direccion_n.length > 0 &&
          this.direccion_numero.length > 0 &&
          this.direccion_cra.length > 0 &&
          this.tipo_via != 1 &&
          this.tipo_via != 12 &&
          this.tipo_via != null
        ) {
          this.items_select_tipo_via.forEach((element) => {
            if (element.clave == this.tipo_via) {
              //Se le agrega a la direccion completa el tipo de via seleccionado,asi mismo como la carrera,el numero y el numeral de la direccion
              direccion_completa +=
                element.valor +
                " " +
                this.direccion_cra +
                " #" +
                this.direccion_numero +
                " -" +
                this.direccion_n +
                " ";

              //Se le agrega a la direccion completa la nomenclatura en la dian del tipo de via seleccionado,asi mismo como la carrera,el numero y el numeral de la direccion
              direccion_dian +=
                element.abreviacion_tipo_via +
                " " +
                this.direccion_cra +
                "  " +
                this.direccion_numero +
                " " +
                this.direccion_n +
                " ";
            }
          });
        }

        //Se valida si el tipo de via es igual a 12(Otros) y se valida que el numeral de la direccion O el numero O la carrera no esten vacios ya que se puede guardar la direccion con que tenga al menos algo escrito en alguno
        if (
          this.tipo_via == 12 &&
          (this.direccion_n.length > 0 ||
            this.direccion_numero.length > 0 ||
            this.direccion_cra.length > 0)
        ) {
          //Se le asigna simplemente a la direccion la carrera,el numero y el numeral de la direccion
          direccion_completa +=
            "" +
            " " +
            this.direccion_cra +
            " " +
            this.direccion_numero +
            " " +
            this.direccion_n +
            " ";

          //Se le asigna simplemente a la direccion de la dian la carrera el numero y el numeral de la direccion
          direccion_dian +=
            "" +
            " " +
            this.direccion_cra +
            "  " +
            this.direccion_numero +
            " " +
            this.direccion_n +
            " ";
        }
        //Se busca el barrio seleccionado
        this.items_select_barrio.forEach((element) => {
          if (element.clave == this.barrio) {
            //Se le agrega a la direccion completa el nombre del barrio
            direccion_completa += element.barrio + " ";
            //Se le agrega a la direccion de la dian el prefijo para el barrio 'BRR' y el nombre del barrio
            direccion_dian += "BRR " + element.barrio.toUpperCase() + " ";
          }
        });

        //Se busca el nombre de la direccion seleccionada
        this.ciudades.forEach((element) => {
          if (element.clave == this.ciudad) {
            //Se le agrega a la direccion completa el nombre de la ciudad
            direccion_completa += element.valor;
          }
        });

        //Se valida si la direccion tiene algo escrito en las declaraciones quitandole hasta los espacios
        if (this.aclaraciones.replace(/ /g, "") != "") {
          //Se la concatena a la direccion completa las declaraciones hechas
          direccion_completa += " (" + this.aclaraciones + ")";
        }
        this.$emit("guardar_direccion", {
          direccion_manual: "",
          id_ciudad: this.ciudad,
          con_covertura: this.con_covertura,
          id_munydpt: this.id_munydpt,
          id_barrio: barrio,
          id_tipo_bloque_interior: this.bloque_interior,
          id_tipo_inmueble: this.tipo_inmueble,
          id_tipo_via: this.tipo_via,
          tipo_via_complemento_1: this.direccion_cra,
          tipo_via_complemento_2: this.direccion_numero,
          tipo_via_complemento_3: this.direccion_n,
          tipo_inmueble_complemento: this.n_tipo_inmueble,
          tipo_bloque_interior_complemento: this.n_bloque_interior,
          coordenadas_latitud: this.latitud,
          coordenadas_longitud: this.longitud,
          direccion_completa: direccion_completa,
          direccion_dian: direccion_dian,
          id_edificio: this.edificio,
          observaciones: this.observaciones,
          aclaraciones:
            this.aclaraciones.replace(/ /g, "") != "" ? this.aclaraciones : "",
          observaciones_barrio: this.observaciones_barrio,
          parqueadero: this.switch_parqueadero ? 1 : 0,
          es_direccion_manual: false,
        });
      } else {
        //En el caso de que la direccion manual este activa
        //Se le asigna al campo direccion completa el campo en la direccion manual
        direccion_completa = this.direccion_manual;
        if (this.edificio != null) {
          //Se valida que en la seleccion del barrio se selecciono un edificio
          this.items_select_barrio.forEach((element) => {
            if (element.clave == this.barrio) {
              //Si le asigna a la direccion completa el prefijo unidad residencial y el nombre del edificio seleccionado
              direccion_completa += " Unidad Residencial " + element.edificio;
            }
          });
        }
        this.$emit("guardar_direccion", {
          direccion_manual: this.direccion_manual,
          id_ciudad: this.ciudad,
          con_covertura: this.con_covertura,
          id_munydpt: this.id_munydpt,
          id_barrio: barrio,
          id_tipo_bloque_interior: 1,
          id_tipo_inmueble: 1,
          id_tipo_via: 1,
          tipo_via_complemento_1: "",
          tipo_via_complemento_2: "",
          tipo_via_complemento_3: "",
          tipo_inmueble_complemento: "",
          tipo_bloque_interior_complemento: "",
          coordenadas_latitud: this.latitud,
          coordenadas_longitud: this.longitud,
          direccion_completa: direccion_completa,
          direccion_dian: null,
          id_edificio: this.edificio,
          observaciones: this.observaciones,
          aclaraciones: "",
          observaciones_barrio: this.observaciones_barrio,
          parqueadero: this.switch_parqueadero ? 1 : 0,
          es_direccion_manual: true,
        });
      }

      this.$emit("cerrar");
    },
    /**
     * Funcion que busca los barrios de
     * @param {integer} event id de la ciudad a buscar los barrios
     * @returns {void}
     */
    async buscarBarrios(event) {
      //Se reinician los valores del select
      this.items_select_barrio = [];
      this.barrio = null;

      //Se revisa si la ciudad tiene covertura
      if (this.ciudades.length > 0) {
        this.ciudades.forEach((ciudad) => {
          if (ciudad.clave == this.ciudad) {
            this.con_covertura = ciudad.con_covertura;
            this.id_munydpt = ciudad.id_munydpt;
          }
        });
      }

      //Se activa el input de carga
      this.cargando_barrios = true;
      await generalService
        .getBarriosEdificiosComunas(event)
        .then((response) => {
          if (response.data.length > 0) {
            //Se crea una variable que va a hacer de identificador para el select de los barrios - unidades residenciales y comunas para queal menos aparezca un barrio sin un edificio y este sea su identificador
            let clave_provisional = -1;

            let barrios = response.data;

            //Se itera sobre los barrios encontrados
            barrios.forEach((element) => {
              //Se agrega un item al select de los barrios que va contener solo el nombre del barrio y la comuna a la que pertenece y va contener la clave provisional
              this.items_select_barrio.push({
                valor: element.barrio + " - " + element.comuna,
                clave: clave_provisional,
                id_barrio: element.id,
                barrio: element.barrio,
                id_edificio: null,
                edificio: null,
                comuna: element.comuna,
                observaciones: element.observaciones,
              });
              //Se valida si el barrio sobre el que se esta iterando tiene al menos un edificio
              if (element.edificios.length > 0) {
                //Se le asigna los edificios a una variable para poder iterar mas facil
                let edificios = element.edificios;

                //Por cada edificio que se encuentre de un barrio se va ingresar un elemento al select de los barrios - unidades residenciales - comunas
                edificios.forEach((edificio) => {
                  this.items_select_barrio.push({
                    valor:
                      element.barrio +
                      " - " +
                      edificio.edificio +
                      " - " +
                      element.comuna,
                    clave: edificio.id, //La clave o identificador de este en el array va ser el id del edificio ya que si se pone el id del barrio no tendria sentido ya que muchas opciones tendrian el mismo identificador
                    id_barrio: element.id,
                    barrio: element.barrio,

                    id_edificio: edificio.id,
                    edificio: edificio.edificio,
                    comuna: element.comuna,
                    observaciones: element.observaciones,
                  });
                });
              }
              clave_provisional -= 1;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            title: "Error al traer los Barrios ",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            timer: 2000,
            position: "center",
            showConfirmButton: false,
            confirmButtonText: "OK",
          });
        });

      this.cargando_barrios = false;
    },
    /**
     * Funcion que asigna tanto el edificio seleccionado como el nombre de la comuna y las observaciones que tiene esta
     * @param {integer} event barrio - unidad residencial - comuna seleccionada
     * @return {void}
     */
    buscarEdificios(event) {
      this.items_select_barrio.forEach(async (element) => {
        if (event == element.clave) {
          this.edificio = element.id_edificio;
          this.comuna = element.comuna;
          this.observaciones_barrio = element.observaciones;
        }
      });
    },

    /**
     * Funcion que guarda las cordenadas del usuario (Temporalmente no funcional)
     */
    saveLocation() {
      this.latitud = this.$refs.geo.markerLatLng[0];
      this.longitud = this.$refs.geo.markerLatLng[1];
    },

    /**
     * Funcion que reinicia los valores de los campos
     * @param {integer} value identificador del tipo de item seleccionado
     * @param {string} item nombre del tipo de select que llamo la funcion
     */
    reiniciarValor(value, item) {
      //Valida si el identificador es igual a 1(No aplica) y si pertenece al campo de tipo de bloque interior
      if (value == 1 && item == "bloque") {
        this.n_bloque_interior = "";
        const printerError = ($) =>
          [($.match(/[n-z]/g) || []).length, $.length].join("/");
      }
      //Valida si el identificador es igual a 1(No aplica) y si pertenece al campo de tipo de inmuble
      if (value == 1 && item == "inmueble") {
        this.n_tipo_inmueble = "";
      }
      //Valida si el identificador es igual a 1(No aplica) y si pertenece al campo de tipo de via
      if (value == 1 && item == "direccion") {
        this.direccion_n = "";
        this.direccion_numero = "";
        this.direccion_cra = "";
      }
    },

    /**
     * Funcion que valida el tipo de direccion que se va a utilizar, si es la mas sencilla (direccion_manual,ciudad,barrio,observaciones)
     * o la mas compleja o especifica que va construyendo la direccion a partir de las opciones seleccionadas menos de la direccion manual
     *
     * @returns {void}
     */
    validarTipoDireccion() {
      //Se valida que si todos los campos que implican escritura en la vista estan vacios(Menos los campos opcionales que son las observaciones y las aclaraciones)

      // console.log(this.direccion_manual.length);
      // console.log(this.n_bloque_interior.length);
      // console.log(this.n_tipo_inmueble.length);
      // console.log( this.direccion_cra.length);
      // console.log( this.direccion_numero.length);
      // console.log( this.direccion_n.length);

      if (
        this.direccion_manual.length == 0 &&
        this.n_bloque_interior.length == 0 &&
        this.n_tipo_inmueble.length == 0 &&
        this.direccion_cra.length == 0 &&
        this.direccion_numero.length == 0 &&
        this.direccion_n.length == 0
      ) {
        this.direccion_neutra = true;
        this.direccion_manual_inactiva = false;
        this.direccion_seleccionable_inactiva = false;

        //En el segundo caso se valida si en la direccion manual hay algo escrito y se desactivan los campos de la direccion mas especifica
      } else if (
        this.direccion_manual.length != 0
        //&& this.n_bloque_interior.length == 0 &&
        // this.n_tipo_inmueble.length == 0 &&
        // this.direccion_cra.length == 0 &&
        // this.direccion_numero.length == 0 &&
        // this.direccion_n.length == 0
      ) {
        this.direccion_neutra = false;
        this.direccion_manual_inactiva = false;
        this.direccion_seleccionable_inactiva = true;
        //Si no se cumplio nunguno de los casos anteriores la direccion manual se desactiva en el script pero no en la vista y los campos para la direccion mas especifica se activan
      } else {
        this.direccion_neutra = false;
        this.direccion_manual_inactiva = true;

        this.direccion_seleccionable_inactiva = false;
      }
    },
  },
  computed: {
    /**Funcion que valida el poder guardar la direccion */
    validarBtnGuardar() {
      //Valida que la direccion especifica este desactivada
      if (!this.direccion_seleccionable_inactiva) {
        //1.Valida que el bloque interior sea diferente de 1 (No aplica) y que el numero de bloque interior no este vacio
        //2.Valida que el tipo de inmueble sea diferente de 1 (No aplica ) y que el numero de tipo de inmueble no este vacio
        //3.Valida que el tipo de via sea dirente de 1 (No aplica) y que el numero del tipo de inmueble no este vacio
        if (
          (this.bloque_interior != 1 && this.n_bloque_interior != "") ||
          (this.tipo_inmueble != 1 && this.n_tipo_inmueble != "") ||
          (this.tipo_via != 1 &&
            this.tipo_via != null &&
            this.direccion_cra != "" &&
            this.direccion_numero != "" &&
            this.direccion_n != "")
        ) {
          //Valida si el barrio esta seleccionado y la ciudad tambien esta seleccionada
          if (this.barrio != null && this.ciudad != null) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        //Si la direccion especifica esta desactivada

        //Valida que en la direccion manual si haya escrito algo y que tanto la ciudad como el barrio hayan sido selecciondos
        if (
          this.direccion_manual.length > 0 &&
          this.barrio != null &&
          this.ciudad != null
        ) {
          return true;
        }
      }
    },
  },
  created() {
    this.cargando_ciudades = true;

    //TRAE LAS CIUDADES DE COLOMBIA
    generalService
      .getDepartamentosColombia()
      .then((response) => {
        this.cargando_ciudades = false;
        this.ciudades = response.data;

        //Se revisa si la ciudad tiene covertura
        if (this.ciudades.length > 0) {
          this.ciudades.forEach((ciudad) => {
            if (ciudad.clave == this.ciudad) {
              this.con_covertura = ciudad.con_covertura;
              this.id_munydpt = ciudad.id_munydpt;
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.$swal.fire({
          title: "Error al traer las ciudades ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "OK",
        });

        this.cargando_ciudades = false;
      });

    this.cargando_barrios = true;

    //TRAE LOS BARRIOS EDIFICIOS Y COMUNAS DE LA CIUDAD ACTUAL
    generalService
      .getBarriosEdificiosComunas(this.ciudad)
      .then((response) => {
        if (response.data.length > 0) {
          //Se crea una variable que va a hacer de identificador para el select de los barrios - unidades residenciales y comunas para queal menos aparezca un barrio sin un edificio y este sea su identificador
          let clave_provisional = -1;

          let barrios = response.data;

          //Se agrega un item al select de los barrios que va contener solo el nombre del barrio y la comuna a la que pertenece y va contener la clave provisional
          barrios.forEach((element) => {
            this.items_select_barrio.push({
              valor: element.barrio + " - " + element.comuna,
              clave: clave_provisional,
              id_barrio: element.id,
              barrio: element.barrio,
              id_edificio: null,
              edificio: null,
              comuna: element.comuna,
              observaciones: element.observaciones,
            });
            //Se valida si el barrio sobre el que se esta iterando tiene al menos un edificio
            if (element.edificios.length > 0) {
              //Se le asigna los edificios a una variable para poder iterar mas facil
              let edificios = element.edificios;

              edificios.forEach((edificio) => {
                //Por cada edificio que se encuentre de un barrio se va ingresar un elemento al select de los barrios - unidades residenciales - comunas
                this.items_select_barrio.push({
                  valor:
                    element.barrio +
                    " - " +
                    edificio.edificio +
                    " - " +
                    element.comuna,
                  clave: edificio.id, //La clave o identificador de este en el array va ser el id del edificio ya que si se pone el id del barrio no tendria sentido ya que muchas opciones tendrian el mismo identificador
                  id_barrio: element.id,
                  barrio: element.barrio,

                  id_edificio: edificio.id,
                  edificio: edificio.edificio,
                  comuna: element.comuna,
                  observaciones: element.observaciones,
                });
              });
            }
            clave_provisional -= 1;
          });

          //Se valida si ya hay una direccion guardada
          if (this.json_direccion != null) {
            //Se busca en el select del barrio - edificio - comuna
            this.items_select_barrio.forEach((element) => {
              //Se valida si lo que se encontro en la direccion guardada coincide con algun elemento del arra de barrios - edificios- comunas
              if (
                element.id_barrio == this.json_direccion.id_barrio &&
                element.id_edificio == this.json_direccion.id_edificio
              ) {
                this.barrio = element.clave;
                this.buscarEdificios(this.barrio);
              }
            });
          }

          this.cargando_barrios = false;
        }
      })
      .catch((error) => {
        console.log(error);
        this.$swal.fire({
          title: "Error al traer los Barrios ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "OK",
        });
        this.cargando_barrios = false;
      });

    this.cargando_tipos_via = true;

    //TRAE LOS TIPOS DE VIAS EXISTENTES
    generalService
      .getTipoVias()
      .then((response) => {
        this.cargando_tipos_via = false;
        this.items_select_tipo_via = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.$swal.fire({
          title: "Error al traer los tipos de via ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "OK",
        });
        this.cargando_tipos_via = false;
      });

    this.cargando_tipos_bloque_interior = true;

    //TRAE LOS TIPOS DE BLOQUE INTERIOR
    generalService
      .getTipoBloqueInterior()
      .then((response) => {
        this.cargando_tipos_bloque_interior = false;
        this.items_select_bloque_interior = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.$swal.fire({
          title: "Error al traer los tipos de inmueble ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "OK",
        });
        this.cargando_tipos_bloque_interior = false;
      });

    this.cargando_tipos_inmueble = true;

    generalService
      .getTipoInmueble()
      .then((response) => {
        this.cargando_tipos_inmueble = false;
        this.items_select_tipo_inmueble = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.$swal.fire({
          title: "Error al traer los tipos de inmueble ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          timer: 2000,
          position: "center",
          showConfirmButton: false,
          confirmButtonText: "OK",
        });
        this.cargando_tipos_inmueble = false;
      });

    //Se valida si ya hay una direccion guardada
    if (this.json_direccion != null) {
      //Se asignan todos los datos a la direccion

      this.direccion_manual = this.json_direccion.direccion_manual == null?'':this.json_direccion.direccion_manual;
      this.bloque_interior = this.json_direccion.id_tipo_bloque_interior;
      this.tipo_inmueble = this.json_direccion.id_tipo_inmueble;
      this.tipo_via = this.json_direccion.id_tipo_via;
      this.direccion_cra =
        this.json_direccion.tipo_via_complemento_1 == null
          ? ""
          : this.json_direccion.tipo_via_complemento_1;
      this.direccion_numero =
        this.json_direccion.tipo_via_complemento_2 == null
          ? ""
          : this.json_direccion.tipo_via_complemento_2;
      this.direccion_n =
        this.json_direccion.tipo_via_complemento_3 == null
          ? ""
          : this.json_direccion.tipo_via_complemento_3;
      this.n_tipo_inmueble =
        this.json_direccion.tipo_inmueble_complemento == null
          ? ""
          : this.json_direccion.tipo_inmueble_complemento;

      this.n_bloque_interior =
        this.json_direccion.tipo_bloque_interior_complemento == null
          ? ""
          : this.json_direccion.tipo_bloque_interior_complemento;

      this.observaciones = this.json_direccion.observaciones;
      this.aclaraciones =
        this.json_direccion.aclaraciones == null
          ? ""
          : this.json_direccion.aclaraciones;

      this.switch_parqueadero = this.json_direccion.parqueadero;
    }

    this.validarTipoDireccion();
  },
};
</script>

<style scoped>
.skeleton-loader {
  border-radius: 3px;
  width: 100%;
  height: 39px;

  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.label_desactivado {
  color: #ccc;
}
.modal_agregar_edificio {
  color: red;
  display: block;
  width: 9px;
}
.modal_agregar_edificio:hover {
  cursor: pointer;
}
.box-direccion {
  background-color: rgb(255, 255, 255);
  color: rgb(139, 139, 139);

  height: 40px;
  padding: 10px 0px 3px 0px;
  border-radius: 0px;
  text-align: center;
  vertical-align: center;
}

/* PERMITE REDUCIR EL ESPACIO ENTRE INPUTS */
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}

/* PERMITE REDUCIR EL ESPACIO ENTRE INPUTS */
</style>
