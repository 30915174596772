<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="text-h5"
        >Declaración de asegurabilidad {{ tipo_de_usuario }}</span
      >
    </v-card-title>
    <v-card-text style="text-align: center">
      <p>
        importante Red Medica vital no es responsable por el tratamiento de
        enfermedad terminal o crónica.
      </p>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          v-for="(tratamiento, index) in tratamientos"
          :key="index"
          class="pa-0 ma-0 pl-8"
        >
          <v-checkbox
            v-if="tratamiento.requiere_seguimiento != 0"
            v-model="tratamientos_seleccionados"
            dense
            :disabled="checkbox_desactivado"
            hide-details
            @click="validarNingunoSeleccionado"
            :label="tratamiento.enfermedad"
            :value="tratamiento.id_enfermedad_asegurabilidad"
          ></v-checkbox>
          <v-checkbox
            v-else
            v-model="tratamientos_seleccionados"
            dense
            hide-details
            @click="validarNingunoSeleccionado"
            :label="tratamiento.enfermedad"
            :value="tratamiento.id_enfermedad_asegurabilidad"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="textarea_observacion"
            outlined
            height="80"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" dark @click="$emit('cerrar-modal')"> Cancelar </v-btn>
      <v-btn
        color="primary"
        @click="
          $emit(
            'aceptar-modal',
            tratamientos_seleccionados,
            textarea_observacion,
            tipo_de_usuario,
            tratamientos_seleccionados_texto
          )
        "
      >
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["tipo_de_usuario", "tratamientos", "tratamientos_seleccionados_prop"],
  data: () => ({
    textarea_observacion: "",

    tratamientos_seleccionados: [],
    tratamientos_seleccionados_texto: [],

    checkbox_desactivado: false,
  }),

  created() {
    this.tratamientos_seleccionados = JSON.parse(
      JSON.stringify(this.tratamientos_seleccionados_prop)
    );


    this.validarNingunoSeleccionado();
  },
  methods: {
    validarNingunoSeleccionado() {
      this.tratamientos_seleccionados_texto = [];
      let ninguno_seleccionado = false;
      let id_ninguno = null;
      if (this.tratamientos.length > 0) {
        if (this.tratamientos_seleccionados.length > 0) {
          this.tratamientos.forEach((tratamiento) => {
            this.tratamientos_seleccionados.forEach(
              (tratamiento_seleccionado) => {
                if (
                  tratamiento.id_enfermedad_asegurabilidad ==
                  tratamiento_seleccionado
                ) {
                  this.tratamientos_seleccionados_texto.push(
                    tratamiento.enfermedad
                  );
                  if (tratamiento.requiere_seguimiento == 0) {
                    ninguno_seleccionado = true;
                    id_ninguno = tratamiento.id_enfermedad_asegurabilidad;
                  }
                }
              }
            );
          });
        }
      }
      if (ninguno_seleccionado) {
        this.tratamientos_seleccionados = [id_ninguno];
        this.tratamientos_seleccionados_texto = ["Ninguna"];
        this.checkbox_desactivado = true;
      } else {
        this.checkbox_desactivado = false;
      }
    },
  },
};
</script>

<style></style>
